const ChairishContext = window.chairisher.context;

/**
 * @returns {Array.<Object>} Collection of indicator image srcset objects to use in the image viewer for seller reviews
 */
export function getReviewImageViewerIndicatorSrcsets() {
    return ChairishContext.REVIEW_IMAGE_VIEWER_INDICATOR_SRCSETS || [];
}

/**
 * @returns {Array.<Object>} Collection of image srcset objects to use in the image viewer for seller reviews
 */
export function getReviewImageViewerSrcsets() {
    return ChairishContext.REVIEW_IMAGE_VIEWER_SRCSETS || [];
}

/**
 * @returns {number} The average rating for a given seller
 */
export function getSellerRating() {
    return ChairishContext.SELLER_RATING;
}

/**
 * @returns {string} The endpoint to get reviews for a given seller
 */
export function getSellerReviewsUrl() {
    return ChairishContext.SELLER_REVIEWS_URL;
}
