import { getSellerRating } from 'chairisher/context/sellerreviews';
import { AmplitudeEventProperty, logAmplitudeClickEvent } from 'chairisher/util/analytics';

const CTA_TYPE = 'seller reviews';

/**
 * @enum {string}
 */
export const SellerReviewsPosition = {
    CARD_IMAGE: 'card image',
    PHOTOS_IMAGE: 'photos image',
    VIEW_ALL_PHOTOS: 'view all photos',
    VIEW_ALL_REVIEWS: 'view all reviews',
};

/**
 * @enum {string}
 */
export const SellerReviewsSubType = {
    IMAGE_VIEWER_OPEN: 'image viewer - open',
    RATING_STARS: 'rating stars',
    VIEW_ALL_REVIEWS: 'view all reviews',
};

/**
 * Tracks a 'click' event for seller reviews
 *
 * @param {string} subtype The subtype of the element that was clicked
 * @param {string} position The position at which the element click is tracked
 * @param {string=} url Optional url of the element that was clicked
 */
export function trackSellerReviewsClickEvent(subtype, position, url = null) {
    logAmplitudeClickEvent(`${CTA_TYPE} - ${subtype}`, position, url, {
        [AmplitudeEventProperty.SELLER_RATING]: getSellerRating(),
    });
}
